export const defaultTranslations: Record<string, string> = {
  field_add_nickname_link: 'Add nickname',
  field_edit_nickname_link: 'Edit nickname',
  field_team: 'Team',
  field_members: 'Members',
  field_team_manager: 'Team Manager',
  field_team_members: 'Team Members',
  field_direct_reports: 'Direct reports to $1',
  field_my_content_lang: 'My Content Language',
  field_people: 'People',
  field_address: 'Address',
  field_code_of_conduct: 'Code of Conduct',
  field_content_language_descripti:
    'All content will be shown in your chosen language. In case your language is not available for some contents, English will be shown instead.',
  field_cost_center: 'Cost Center',
  field_edit_profile_data_link: 'Edit profile data',
  field_employee_id: 'Employee ID',
  field_language_settings: 'Language Settings',
  field_logout_link: 'Logout',
  field_my_contents_link: 'My Contents',
  field_my_language: 'My Language',
  field_my_profile_link: 'My Profile',
  field_news: 'News',
  field_office_email: 'Office email',
  field_office_mobile_number: 'Office mobile number',
  field_office_phone_number: 'Office phone number',
  field_search: 'Search',
  field_sefar_group: '$1 Sefar Group.',
  field_short_name: 'Short name',
  field_spaces: 'Spaces',
  field_spaces_articles: 'Spaces Articles',
  field_spaces_all_favourites: 'All favourites',
  field_favourites_empty_title: 'No favourites saved yet',
  field_favourites_empty_desc:
    'Save subspaces or pages as favourites to help you find them quicker',
  field_favourites_empty_cta: 'Discover pages',
  field_see_all: 'See all',
  field_no_results_found: 'No results found.',
  field_results_for: 'results for',
  field_results_in: 'in',
  field_search_input_text:
    'Search for people, tools, documents, topics or spaces',
  field_search_input_text_sm: 'Search',
  field_search_by_title: 'Search by title',
  field_sort_most_relevant: 'Most relevant',
  field_sort_latest: 'Latest',
  field_sort_oldest: 'Oldest',
  field_sort_updated_asc: 'Last Updated',
  field_sort_updated_desc: 'First Updated',
  field_my_favourites: 'My favourites',
  field_all: 'All',
  field_edit_nickname_description:
    'You are searchable by your nickname. It is an optional item, you can delete it anytime.',
  field_add_nickname_description:
    'You will be searchable by your nickname. Your nickname will be displayed in brackets after your last name.',
  field_nickname: 'Nickname',
  field_nickname_update_success: 'Your nickname has been updated successfully!',
  field_cancel: 'Cancel',
  field_save: 'Save',
  field_confirm: 'Confirm',
  field_contact_hr: 'Contact local HR',
  field_contact_hr_description:
    'For editing profile data, please go to rexx or contact your local HR.',
  field_okay: 'Okay',
  field_add_news: 'Add News',
  field_news_archive: 'News Archive',
  field_go_to: 'Go to',
  field_looking_for_news: 'Looking for a specific news?',
  field_load_10_more: 'Load 10 more',
  field_load_more: 'Load $1 more',
  field_select_country: 'Select country',
  field_error_common_toast_text: 'Some error occurred. Please try again later.',
  field_search_input_spaces_text: 'Search for articles in spaces',
  field_add_space_article: 'Add Article',
  field_rename_subspace_title: 'Rename subspace',
  field_rename_subspace_description: 'Name of this subspace',
  field_subspace_edit_image: 'Edit image',
  field_update_profile_picture: 'Update profile picture',
  field_upload_from_local: 'Upload from local',
  field_edit: 'Edit',
  field_comments_add_your_comment: 'Add your comment...',
  field_comment_added_toast_text: 'Your comment has been added successfully!',
  field_comment_add_reply_to_text: "Add your reply to $1 $2's comment...",
  field_reply: 'Reply',
  field_post: 'Post',
  field_show_more: 'Show more',
  field_show_less: 'Show less',
  field_profile_pic_updated_toast:
    'Your profile picture has been updated successfully!',
  field_edit_prof_pic_zoom: 'Zoom',
  field_edit_prof_pic_straighten: 'Straighten',
  field_edit_prof_pic_drag_drop: 'Drag and drop image',
  field_settings_cont_lang_updated:
    'The content language has been updated successfully!',
  field_settings_int_lang_updated:
    'The interface language has been updated successfully!',
  field_settings_select_lang_text: 'Select a language…',
  field_tn_translate_page: 'Translate page',
  field_tn_show_original: 'Show original',
  field_tn_page_is_in_lng: 'Page is in',
  field_tn_page_trans_from_to: 'Page translated from $1 to $2',
  field_show_all_results: 'Show all results',
  field_error_tool_save_toast:
    'Some error occurred while saving your link. Please try again later.',
  field_success_tool_add_toast: 'The link has been added successfully!',
  field_error_update_tool_toast:
    'Some error occurred while updating tools. Please try again later.',
  field_success_update_tools_toast:
    'Tools configuration has been updated successfully!',
  field_done: 'Done',
  field_tools_add_description_text:
    'Add a link to customize your tools. Make sure the link you insert is valid and starts with "https://"',
  field_tools_url_input_plchldr: 'Enter url',
  field_tools_name_input_label: 'Name',
  field_tools_name_input_plchldr: 'Enter name of link',
  field_first: 'First',
  field_next: 'Next',
  field_previous: 'Previous',
  field_last: 'Last',
  field_tools_all_tools_text: 'All Tools',
  field_tools_add_tools_text: 'Add Tools',
  field_tools_add_link_text: 'Add link',
  field_news_categories_text: 'Categories',
  field_comments_count_of_comments: 'Comments',
  field_settings_link: 'Language settings',
  field_news_tab_my_news: 'My News',
  field_news_tab_global: 'Global',
  field_ideas: 'Ideas',
  field_status_new: 'New',
  field_status_progress: 'In Progress',
  field_status_completed: 'Completed',
  field_status_rejected: 'Rejected',
  field_search_by_title_author: 'Search by title, author',
  field_last_update: 'Last Update',
  field_submitted_on: 'Submitted on',
  field_title: 'Title',
  field_status: 'Status',
  field_author: 'Author',
  field_idea_implementer: 'Implementer',
  field_idea_only_assigned_to_me: 'Only assigned to me',
  field_idea_add_idea: 'Add idea',
  field_feedback: 'Feedback',
  field_clear_filters: 'Reset',
  field_idea_new_idea: 'New Idea',
  field_idea_new_idea_descr:
    'We are excited that you want to send a new idea for improving Sefar! Fill in the form explaining in detail your idea: which problem will it tackle and how.',
  field_idea_form_lang: 'Language',
  field_idea_form_lang_descr: 'In which language are you submitting your idea?',
  field_idea_form_country: 'Country',
  field_idea_form_location: 'Location',
  field_idea_form_implementer: 'Implementer',
  field_idea_form_implementer_desc:
    'Select the Implementer who will review the idea and will be in charge of implementation.',
  field_idea_form_country_descr:
    'To which country does your idea apply? Choose one or more options',
  field_idea_form_location_descr:
    'To which location does your idea apply? Choose one or more options',
  field_idea_form_title_and_descr: 'Title and description',
  field_idea_form_title_label: 'Title of your idea',
  field_idea_form_title_plchldr: 'Write a short title',
  field_idea_form_problem_label: 'Which problem will your idea tackle?',
  field_idea_form_common_plchldr: 'Write short and clear text',
  field_idea_form_improve_label: 'How does the improvement look like?',
  field_idea_form_aims_label: 'What are the aim and benefits of your proposal?',
  field_idea_form_who_label: 'Who needs to be involved in the implementation?',
  field_idea_form_category: 'Category',
  field_idea_form_category_descr:
    'To which area of work does your idea apply? Choose one or more options.',
  field_idea_form_privacy: 'Privacy',
  field_idea_form_privacy_descr:
    'When you send us an idea, you agree to publish your name with it.',
  field_idea_form_privacy_chckbx: 'Publish my name',
  field_submit: 'Submit',
  field_idea_save_toast_success: 'Idea has been created successfully!',
  field_idea_edit_toast_success: 'Idea has been updated successfully!',
  field_idea_descr_of_problem: 'Description of the problem',
  field_idea_improve_idea: 'Improvement idea',
  field_idea_aim_and_benefits: 'Objectives and benefits',
  field_idea_people_involved: 'People involved',
  field_country: 'Country',
  field_idea_edit_feedback: 'Edit feedback',
  field_idea_edit_feedback_success: 'Feedback edited',
  field_idea_assess: 'Assess idea',
  field_idea_assess_success: 'Idea is assessed.',
  field_idea_assess_decision: 'Explain your decision',
  field_idea_assess_rejected: 'Rejected: the idea should not be implemented',
  field_idea_assess_approved: 'Approved: the idea will be implemented',
  field_idea_in_progress: 'Set to implementation in progress',
  field_idea_realization_date: 'Change date of realization',
  field_idea_realization_date_scss: 'Realization date changed',
  field_idea_set_in_progress: 'Set implementation in progress',
  field_idea_realization_date_desc:
    'If possible provide an estimated date of realization',
  field_idea_realization_date_name: 'Estimated date of realization',
  field_idea_in_progress_success: 'Idea is in progress.',
  field_idea_implemented: 'Set to implemented',
  field_idea_implemented_confirm_t: 'Set idea to implemented?',
  field_idea_implemented_confirm_d:
    'Do you want to set the status of this idea to implemented? This action is not reversible.',
  field_idea_implemented_desc:
    'Give a brief explanation how this idea was implemented.',
  field_idea_implemented_summary: 'Summary',
  field_idea_implemented_success: 'Idea is implemented.',
  field_idea_status_new: 'New',
  field_idea_status_not_considered: 'Not Considered',
  field_idea_status_pending: 'Review Pending',
  field_idea_status_approved: 'Approved',
  field_idea_status_rejected: 'Rejected',
  field_idea_status_in_progress: 'Implementation in Progress',
  field_idea_status_implemented: 'Implemented',
  field_idea_status_rewarded: 'Rewarded',
  field_idea_confirm_thank_you: 'Thank you for submitting your idea!',
  field_idea_confirm_descr_text:
    'Great that you took the time to submit an idea for improving our workplace. Your idea will be evaluated. Once assigned to an Implementer, you will be able to track the status on the Ideas list.',
  field_idea_confirm_back_text: 'Back to ideas list',
  field_idea_validation_success: 'Your idea validation is submited!',
  field_idea_costs_required: 'Expected implementation costs are required',
  field_idea_validation_title: 'Validate Idea',
  field_idea_validation_caption: 'Idea Validation',
  field_idea_confirm_title: 'Confirm idea',
  field_idea_savings_title: 'Expected savings per year CHF',
  field_idea_turnover_title: 'Expected increase of turnover per year CHF',
  field_idea_costs_title: 'Expected implementation costs',
  field_idea_strategy_categories:
    'Which strategic category does this idea focus on?',
  field_idea_type_new: 'Idea is new',
  field_idea_type_improvement: 'Idea is an improvement',
  field_idea_impacts_process: 'The idea impacts an existing process or product',
  field_idea_impacts_environment:
    'The idea is essential for safety or environmental reasons',
  field_idea_impacts_patenting: 'The idea may affect a later patenting',
  field_idea_impacts_confidential:
    'The idea affects longterm know-how which is considered confidential',
  field_idea_implementer_title: 'Are you going to proceed with the idea?',
  field_idea_feedback_label: 'Explain your decision',
  field_idea_impact_title: 'How does the idea impact the organization?',
  field_idea_feedback_required: 'Idea feedback is required',
  field_idea_my_ideas: 'My Ideas',
  field_idea_view_all: 'View all',
  field_idea_top_ideas: 'Top Ideas',
  field_idea_week: 'Week',
  field_idea_month: 'Month',
  field_idea_year: 'Year',
  field_ideas_add_your_idea: 'Add your first idea',
  field_notice: 'Notifications',
  field_notice_last_seven: 'Last 7 days',
  field_notice_older: 'Older Notifications',
  field_notice_comment_spaces: '$1 commented on your space article $2.',
  field_notice_comment_news: '$1 commented on your news article $2.',
  field_notice_comment_ideas: '$1 commented on your idea $2.',
  field_notice_reply_spaces:
    '$1 replied to your comment on the space article $2.',
  field_notice_reply_news: '$1 replied to your comment on the news article $2.',
  field_notice_reply_ideas: '$1 replied to your comment on the idea $2.',
  field_notice_like_spaces: '$1 liked your news space $2.',
  field_notice_like_news: '$1 liked your news article $2.',
  field_notice_idea_ideas: '$1 created an idea $2.',
  field_notice_idea_reminder_ideas: 'Idea $2 is awaiting for an update.',
  field_notice_idea_status_ideas: 'Status of your idea $2 was changed.',
  field_notice_idea_assigned_ideas: 'An idea $2 was assigned to you.',
  field_notice_pr_created:
    '$1 has placed Purchase Requisition: $2 and is waiting for your approval!',
  field_notice_pr_reworked:
    '$1 has reviewed the Purchase Requisition $2 and is waiting for your approval!',
  field_notice_pr_needs_rework: 'Purchase Requisition $1 needs to be reviewed',
  field_notice_pr_sync_approved:
    'Purchase Requisition $2 has been approved by $1 and sent to the purchasing team',
  field_notice_pr_sync_error:
    'Purchase Requisition $1 has an error, please check and send to status "to be reviewed"',
  field_notice_type_all: 'All notification types',
  field_notice_type_like: 'Like',
  field_notice_type_reply: 'Reply',
  field_notice_type_comment: 'Comment',
  field_notice_type_idea: 'New Idea',
  field_notice_type_idea_status: 'Idea Status',
  field_notice_type_assigned: 'Idea Assigned',
  field_notice_act_like: 'Go to page',
  field_notice_act_comment: 'Reply',
  field_notice_act_read: 'Mark as read',
  field_notice_only_unread: 'Only unread',
  field_notice_mark_all_as_read: 'Mark all as read',
  field_date_today: 'Today',
  field_date_yesterday: 'Yesterday',
  field_date_2days_ago: '2 days ago',
  field_date_3days_ago: '3 days ago',
  field_login_send_new_code: 'Send new code',
  field_login_back_to_start: 'Back to start',
  field_login_error_msg: 'The user name or password you entered is incorrect.',
  field_login_time_remaining: 'Time remaining',
  field_login_no_code_question: "Didn't receive code?",
  field_login_login_btn: 'Login',
  field_login_login_with_phone: 'Log in with my mobile phone',
  field_login_no_worries_msg:
    "Don’t worry! Your phone number won't be seen publicly and is only needed for your login.",
  field_login_form_phone_number: 'Phone number',
  field_login_form_send_code: 'Send code to my phone',
  field_login_with_sefar_acc_text: 'Log in with Sefar account',
  field_login_notice_phone_msg:
    'Use your mobile phone number from Rexx. To add or change it, contact your local HR.',
  field_or: 'or',
  field_404_page_title: 'Oops, page not found',
  field_404_page_text:
    'The page you are looking for no longer exists or has been removed.',
  field_404_page_links_text: 'Please use our $1 or see our $2',
  field_clear_search_filters: 'Clear filters',
  field_recent_search_results: 'Recent search results',
  field_webex_active: 'Active',
  field_webex_call: 'On Call',
  field_webex_donotdisturb: 'Do not Disturb',
  field_webex_incative: 'Away',
  field_webex_meeting: 'In a calendar meeting',
  field_webex_outofoffice: 'Out of office',
  field_webex_pending: 'Invite Pending',
  field_webex_presenting: 'Presenting',
  field_webex_unknown: 'Not indentified',
  field_webex_availability: 'Connect webex to see availability',
  field_webex_open: 'Open in Webex',
  field_webex_connect: 'Connect Webex',
  field_greetings_day: 'Good Day',
  field_greetings_morning: 'Good Morning',
  field_greetings_evening: 'Good Evening',
  field_idea_manager_dashboard: 'Idea manager dashboard',
  field_idea_created_after: 'Created after',
  field_idea_department: 'Department',
  field_purchase_item: 'Item',
  field_purchase_details: 'Details',
  field_purchase_overview: 'Overview',
  field_purchase_finished: 'Send for approval',
  field_save_as_draft: 'Save as draft',
  field_update_draft: 'Update draft',
  field_back: 'Back',
  field_purchase_close_confirmation_title:
    'Do you want to close purchase requisition?',
  field_purchase_close_confirmation_body:
    'You won’t be able to go back to it. If you would like to go back to it use “Save as draft” button',
  field_purchase_order_title: 'Purchase Requisition Order',
  field_purchase_order_search_title: 'Add an existing item',
  field_purchase_order_search_placeholder: 'Search by xxxxxxxx',
  field_purchase_order_search_supplier: 'Supplier item no',
  field_purchase_order_search_item_number: 'Item number',
  field_purchase_order_search_add: 'Add',
  field_purchase_order_overview_title: 'Purchase Requisition Overview',
  field_purchase_order_add_new_custom_item: 'Add new custom item',
  field_purchase_order_total_amount: 'Total amount',
  field_purchase_requisition_item_note: 'Item note',
  field_purchase_requisitions_title: 'Purchase Requisitions',
  field_purchase_requisitions_search_placeholder: 'Search by supplier',
  field_purchase_requisitions_status_filter_placeholder: 'Status',
  field_purchase_requisition_actions_delete: 'Delete',
  field_purchase_requisition_actions_edit: 'Edit',
  field_purchase_requisition_actions_start_approval: 'Start approval',
  field_purchase_requisition_actions_approve: 'Approve',
  field_purchase_requisition_actions_reject: 'Reject',
  field_purchase_requisition_actions_to_be_reviewed: 'To be reviewed',
  field_purchase_requisition_delete_title: 'Remove purchase requisition',
  field_purchase_requisition_delete_message:
    'Do you want to delete purchase requisition? This action is not reversible.',
  field_purchase_add_order: 'Add Purchase Requisition',
  field_purchase_requisition_create_completed_title: 'Completed',
  field_purchase_requisition_create_completed_cta:
    'Go to purchase requisition list',
  field_purchase_requisition_start_approval_title:
    'Do you want to start approval?',
  field_purchase_requisition_start_approval_message:
    'Do you want to start approval? This purchase requisition will be send to approver.',
  field_purchase_requisition_start_approval_send: 'Send for approval',
  field_purchase_requisition_approve_message:
    'Are you sure you want to approve purchase requisition?',
  field_purchase_requisition_reject_message:
    'Are you sure you want to reject purchase requisition?',
  field_purchase_requisition_to_be_reviewed_message:
    'Are you sure you want to set purchase requisition as to be reviewed?',
  field_purchase_requisition_confirmation_comment: 'Comment (optional)',
  field_purchase_requisition_comment_from_approver: 'Comment from approver',
  field_purchase_requisition_comment_from_ln: 'LN Sync log',
  field_purchase_requisition_note: 'Item note',
  field_error_purchase_requisition_items_empty:
    'Add items to your purchase request',
  field_error_purchase_requisition_wrong_currencies:
    'Currency should be the same for every item',
  field_error_purchase_requisition_price: 'Price should be filled',
  field_error_purchase_requisition_quantity: 'Quantity should be filled',
  field_error_purchase_requisition_description: 'Description should be filled',
  field_error_purchase_requisition_del_date: 'Delivery date should be filled',
  field_purchase_requisition_admin_order_change:
    'Administrator changed order items list',
  field_must_read_label: 'Must read',
  field_must_read_checkmark:
    'If selected will be highlighted on top of the news page',
  field_must_read_footer_title: 'This is an important company information.',
  field_must_read_footer_description:
    'Please read it carefully and confirm you have read and understood the information. Thank you!',
  field_must_read_footer_cta: 'Confirm as read'
}
