import useSWR from 'swr'
import { API_DOMAIN, fetcherFullRequest, init } from './common'
import { transferUser, User, USER_TYPE } from './user'
import { formatDateTimeOfficial } from '../components'
import { Attributes, MainMedia, Relationship } from './news'
import { formatArticleDate } from '../components'
import {
  getFileType,
  ImageToAttachToArticle,
  MEDIA_DAM_IMAGE_TYPE,
  UploadProfiles
} from './media'
import { useConfigStore } from '../state/config'
import { formatFileName } from '../utils/formatFileName'
import { useEffect } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'

export const SPACE_TYPE = 'node--space_category'
export const SUBSPACE_TYPE = 'node--space'
export const SPACE_ARTICLE_TYPE = 'node--space_article'

export type SubSpaceArticleSimple = {
  id: string
  title: string
  date: string
  status: boolean
  subspaceId: string
}

export type SubSpaceSimple = {
  id: string
  title: string
  articlesAmount: number
  status: string
  spaceId: string
  date: string
  imageUrl: string | null
}

export type SubSpace = SubSpaceSimple & {
  articles: SubSpaceArticleSimple[]
  owner: any
  langCode?: string
  editors: User[]
}

export type Space = {
  id: string
  title: string
  subspaces: SubSpaceSimple[]
}

export type SpacesBookmarkSimple = {
  id: string
  type: string
}

export type SpacesBookmark = SubSpaceSimple &
  SubSpaceArticleSimple &
  SpacesBookmarkSimple & {
    spaceTitle?: string
    subSpaceTitle?: string
    subSpaceId?: string
    spaceId?: string
  }

export type SpaceArticle = {
  id: string
  drupalInternalNid: string
  date: string
  title: string
  lead: string
  text: string
  commentCount: number
  viewers: number
  status: boolean
  publishDate: string
  unpublishDate: string
  author: User | undefined
  editors?: User[]
  subspace?: SubSpaceSimple
  media: MainMedia | undefined
  lang: string
}

export type SpaceArticlePreview = {
  id: string
  title: string
  date: string
  status: boolean
  subspaceId: string
  spaceName: string | null
  previewImageUrl: string | null
}

export const useIsSpaceEditor = (userId?: string) => {
  const { data, error } = useSWR(
    userId ? [`${API_DOMAIN}/user_entity/my_content_access`, init] : null,
    fetcherFullRequest
  )
  return {
    isLoading: userId ? !error && !data : false,
    isSpaceEditor: data?.has_access
  }
}

export function useSpaces(categoryId?: string) {
  const categoryFilterString = categoryId ? `&category=${categoryId}` : ''
  const { data, error } = useSWR(
    [`${API_DOMAIN}/spaces?${categoryFilterString}`, init],
    fetcherFullRequest
  )

  return {
    spaces: data ? transferSpaces(data?.data, data?.included) : data,
    isLoading: !error && !data,
    isError: error
  }
}

export function useAllowedSpaces(categoryId?: string, userId?: string) {
  const categoryFilterString = categoryId
    ? `&filter[field_space_category.id]=${categoryId}`
    : ''

  const ownerFilter = userId ? `&filter[field_owner.id]=${userId}` : ''
  const { data: ownerData, error: ownerError } = useSWR(
    [
      `${API_DOMAIN}/jsonapi/node/space?include=field_subspace_editors,field_space_category,field_space_media${categoryFilterString}${ownerFilter}&sort=field_space_category.field_space_category_weight,field_space_weight`,
      init
    ],
    fetcherFullRequest
  )

  const editorFilter = userId
    ? `&filter[field_subspace_editors.id]=${userId}`
    : ''
  const { data: editorData, error: editorError } = useSWR(
    [
      `${API_DOMAIN}/jsonapi/node/space?include=field_subspace_editors,field_space_category,field_space_media${categoryFilterString}${editorFilter}&sort=field_space_category.field_space_category_weight,field_space_weight`,
      init
    ],
    fetcherFullRequest
  )
  const ownerSpaces = transferSpaces(ownerData?.data, ownerData?.included)
  const editorSpaces = transferSpaces(editorData?.data, editorData?.included)
  return {
    spaces: [
      ...(ownerSpaces ? ownerSpaces : []),
      ...(editorSpaces ? editorSpaces : [])
    ],
    isLoading: (!ownerError && !ownerData) || (!editorError && !editorData),
    isError: ownerError || editorError
  }
}

export function useSubSpace(id: string) {
  const { contentLang } = useConfigStore.getState()
  const langQuery = contentLang === 'en' ? '' : `/${contentLang}`
  const { data, mutate, error } = useSWR(
    [`${API_DOMAIN}${langQuery}/space_articles?space=${id}`, init],
    fetcherFullRequest
  )

  return {
    subspace: data ? transferSubSpace(data?.data, data?.included) : data,
    mutate,
    isLoading: !error && !data,
    isError: error
  }
}

export function useSpaceArticles(userId?: string): {
  articles: SubSpaceArticleSimple[]
  isLoading: boolean
  isError: Error
} {
  const userFilter = userId ? `&filter[uid.id]=${userId}` : ''
  const { contentLang } = useConfigStore.getState()
  const langQuery = contentLang === 'en' ? '' : `/${contentLang}`
  const { data, error } = useSWR(
    [
      `${API_DOMAIN}${langQuery}/jsonapi/node/space_article?include=field_space,field_space.field_space_category,field_space.field_space_media${userFilter}&sort=field_weight`,
      init
    ],
    fetcherFullRequest
  )

  return {
    articles: data && transferArticlesSimple(data?.data),
    isLoading: !error && !data,
    isError: error
  }
}

export function getSpaceArticleById(id: string) {
  return fetch(
    `${API_DOMAIN}/jsonapi/node/space_article/${id}?include=field_space,field_space.field_space_category`,
    {
      ...init,
      headers: {
        ...init.headers,
        'Content-Type': 'application/vnd.api+json'
      },
      method: 'GET'
    }
  ).then((res) => res && res.json())
}

export function useSpaceArticle(id: string | undefined, lang: string) {
  const langQuery = lang === 'en' ? '' : `/${lang}`
  const { me } = useConfigStore.getState()
  const { data, error, mutate } = useSWR(
    id
      ? [
          `${API_DOMAIN}${langQuery}/jsonapi/node/space_article/${id}?include=uid.user_picture,field_space,field_space.field_owner,field_space.field_subspace_editors,field_space.field_space_category`,
          init
        ]
      : undefined,
    fetcherFullRequest
  )
  const updateViewers = async (field_computed_viewers: number) => {
    const updatedViewersResponse = await updateSpaceArticleViewers(data.data.id)
    if (updatedViewersResponse?.Status === 'View tracked.') {
      mutate(
        set(
          cloneDeep(data),
          'data.attributes.field_computed_viewers',
          field_computed_viewers
        )
      )
    }
  }
  useEffect(() => {
    const currentViewersNumber = data?.data?.attributes?.field_computed_viewers
    if (currentViewersNumber >= 0 && me) {
      updateViewers(currentViewersNumber + 1)
    }
  }, [me, data?.data?.attributes?.field_computed_viewers])
  let author
  const editors = []
  if (data?.included) {
    const subSpace = data?.included.find(
      (item: any) => item?.type === SUBSPACE_TYPE
    )
    for (const node of data.included) {
      // if (node.id === subSpace.relationships?.uid?.data?.id) {
      //   author = transferUser(node, data.included)
      // }
      if (subSpace.relationships?.field_owner?.data?.id === node?.id) {
        author = transferUser(node, data.included)
      }
      if (subSpace.relationships?.field_subspace_editors?.data?.length) {
        for (const editor of subSpace.relationships.field_subspace_editors
          .data) {
          if (editor.id === node.id) {
            editors.push(transferUser(node, data.included))
          }
        }
      }
    }
  }
  return {
    article: data
      ? transferSpaceArticle(
          data.data,
          author,
          data?.included?.find(
            ({ type }: { type: string }) => type === SUBSPACE_TYPE
          ),
          editors
        )
      : data,
    isLoading: !error && !data,
    isError: error
  }
}

export function useSpaceArticlesPreview(
  sort = 'field_weight',
  limit = 3,
  userId?: string
): {
  spaceArticles: SpaceArticlePreview[]
  isLoading: boolean
  isError: Error
} {
  const userFilter = userId ? `&filter[uid.id]=${userId}` : ''
  const { contentLang } = useConfigStore.getState()
  const langQuery = contentLang === 'en' ? '' : `/${contentLang}`
  const { data, error } = useSWR<Record<string, any>>(
    [
      `${API_DOMAIN}${langQuery}/jsonapi/node/space_article?include=field_space,field_space.field_space_category,field_space.field_space_media,field_space.field_space_media.thumbnail&filter[status]=1${userFilter}&sort=${sort}&page[limit]=${limit}`,
      init
    ],
    fetcherFullRequest
  )

  return {
    spaceArticles:
      data?.data?.map((spaceArticle: Record<string, any>) =>
        transferSpaceArticlePreview(spaceArticle, data?.included)
      ) || [],
    isLoading: !error && !data,
    isError: error
  }
}

export function patchSpacesArticle(
  id: string | undefined,
  attributes: Attributes,
  relationships: Relationship | Record<string, unknown>
) {
  const langQuery =
    attributes.langcode === 'en' ? '' : `/${attributes.langcode}`
  return id
    ? fetch(`${API_DOMAIN}${langQuery}/jsonapi/node/space_article/${id}`, {
        ...init,
        headers: {
          ...init.headers,
          'Content-Type': 'application/vnd.api+json'
        },
        method: 'PATCH',
        body: JSON.stringify({
          data: {
            type: SPACE_ARTICLE_TYPE,
            id: id,
            attributes,
            relationships
          }
        })
      })
    : addSpacesArticle(attributes, relationships)
}

export const updateSpaceArticleViewers = async (id: string) => {
  if (id) {
    const response = await fetch(
      `${API_DOMAIN}/space_article/trackview/${id}`,
      {
        ...init,
        headers: {
          ...init.headers,
          'Content-Type': 'application/vnd.api+json'
        },
        method: 'POST'
      }
    )
    const data = await response.json()
    return data
  }
}

export function addSpacesArticle(
  attributes: Attributes,
  relationships: Relationship | Record<string, unknown>
) {
  const langQuery =
    attributes.langcode === 'en' ? '' : `/${attributes.langcode}`
  return fetch(`${API_DOMAIN}${langQuery}/jsonapi/node/space_article`, {
    ...init,
    headers: {
      ...init.headers,
      'Content-Type': 'application/vnd.api+json'
    },
    method: 'POST',
    body: JSON.stringify({
      data: {
        type: SPACE_ARTICLE_TYPE,
        attributes,
        relationships
      }
    })
  })
}

export function transferSpaceArticle(
  { attributes, relationships, id }: any,
  author: User | undefined,
  subspace?: any,
  editors?: User[]
): SpaceArticle {
  if (!attributes) {
    return {
      id: '',
      drupalInternalNid: '',
      date: '',
      title: '',
      lead: '',
      text: '',
      commentCount: 0,
      status: true,
      publishDate: '',
      unpublishDate: '',
      author: undefined,
      media: undefined,
      lang: ''
    }
  }
  const viewers = attributes?.field_computed_viewers ?? 0
  return {
    id,
    drupalInternalNid: attributes.drupal_internal__nid,
    date: formatArticleDate(attributes.created),
    title: attributes.title,
    lead: attributes?.field_space_lead?.value,
    text: attributes?.field_space_text?.value,
    commentCount: attributes?.comment?.comment_count ?? 0,
    viewers,
    status: attributes.status,
    publishDate: attributes.publish_on,
    unpublishDate: attributes.unpublish_on,
    author,
    editors,
    subspace: subspace && transferSubSpaceSimple(subspace),
    media: undefined,
    lang: attributes.langcode
  }
}

export function transferSpaceArticles(
  articles: any,
  included: any
): SpaceArticle[] {
  return [...(articles || [])]?.map((article: any) => {
    return transferSpaceArticle(
      article,
      undefined,
      included.find(
        (include: any) =>
          article?.relationships?.field_space?.data?.id === include?.id
      )
    )
  })
}

export function transferSubSpace(
  articles: any,
  included: any
): SubSpace | null {
  let subSpacePrepared = null
  if (included) {
    const subSpace = included.find((item: any) => item?.type === SUBSPACE_TYPE)
    let owner
    const editors = []
    for (const node of included) {
      if (subSpace.relationships?.field_owner?.data?.id === node?.id) {
        owner = transferUser(node, included)
      }
      if (subSpace.relationships?.field_subspace_editors?.data?.length) {
        for (const editor of subSpace.relationships.field_subspace_editors
          .data) {
          if (editor.id === node.id) {
            editors.push(transferUser(node, included))
          }
        }
      }
    }
    const subSpaceSimple = subSpace ? transferSubSpaceSimple(subSpace) : null

    const imageUrl =
      included.find((item: any) => item?.type === MEDIA_DAM_IMAGE_TYPE)
        ?.attributes?.field_image_original ?? null
    if (subSpaceSimple) {
      subSpacePrepared = {
        ...subSpaceSimple,
        articles: transferArticlesSimple(articles),
        owner,
        editors,
        imageUrl,
        langCode: subSpace?.attributes?.langcode
      }
    }
  }
  return subSpacePrepared
}

export function transferArticleSimple(article: any): SubSpaceArticleSimple {
  return {
    id: article?.id,
    title: article?.attributes?.title,
    date: formatDateTimeOfficial(article?.attributes?.changed),
    status: article?.attributes?.status,
    subspaceId: article?.relationships.field_space.data.id
  }
}

export function transferArticlesSimple(articles: any) {
  return articles.map((article: any) => transferArticleSimple(article))
}

export function transferSubSpaceSimple(
  subspace: any,
  included?: any
): SubSpaceSimple {
  return {
    id: subspace.id,
    title: subspace?.attributes?.title,
    status: subspace?.attributes?.status,
    articlesAmount: subspace?.attributes?.articles_amount
      ? +subspace.attributes.articles_amount
      : 0,
    spaceId: subspace?.relationships?.field_space_category?.data?.id,
    date: formatDateTimeOfficial(subspace?.attributes?.changed),
    imageUrl: included
      ? included?.find(
          (item: any) =>
            item?.id === subspace?.relationships?.field_space_media?.data[0]?.id
        )?.attributes?.field_image_original ?? null
      : null
  }
}

export function transferSpace(space: any, subspaces: any, included: any) {
  const subspacesBySpace = subspaces?.filter(
    (subspace: any) =>
      subspace?.relationships?.field_space_category?.data?.id === space.id
  )
  return {
    id: space.id,
    title: space?.attributes?.title,
    subspaces: subspacesBySpace.map((subspace: any) =>
      transferSubSpaceSimple(subspace, included)
    )
  }
}

export function transferSpaces(
  subspaces: any[],
  included: any[]
): Space[] | undefined {
  return included
    ?.filter(({ type }) => type === SPACE_TYPE)
    ?.map((space: any) => transferSpace(space, subspaces, included))
}

export function transferSpaceArticlePreview(
  spaceArticle: Record<string, any>,
  included?: Record<string, any>
): SpaceArticlePreview {
  const parentSpace = included
    ? included.find(
        (item: any) =>
          item.id === spaceArticle?.relationships.field_space?.data?.id
      ) ?? null
    : null

  const previewImage =
    parentSpace && included
      ? included.find(
          (item: any) =>
            parentSpace.relationships?.field_space_media?.data?.[0]?.id ===
            item.id
        ) ?? null
      : null

  const previewImageThumbnail =
    previewImage && included
      ? included.find(
          (item: any) =>
            previewImage.relationships?.thumbnail?.data?.id === item.id
        ) ?? null
      : null

  const previewImageUrl =
    previewImageThumbnail?.attributes?.image_style_uri.large ?? null

  return {
    id: spaceArticle?.id,
    title: spaceArticle?.attributes?.title,
    date: formatDateTimeOfficial(spaceArticle?.attributes?.changed),
    status: spaceArticle?.attributes?.status,
    spaceName: parentSpace?.attributes.title,
    subspaceId: spaceArticle?.relationships.field_space.data.id,
    previewImageUrl
  }
}

export function editSubspaceEditors({
  subspaceId,
  editorIds
}: {
  subspaceId: string
  editorIds: string[]
}) {
  return fetch(`${API_DOMAIN}/jsonapi/node/space/${subspaceId}`, {
    ...init,
    headers: {
      ...init.headers,
      'Content-Type': 'application/vnd.api+json'
    },
    method: 'PATCH',
    body: JSON.stringify({
      data: {
        type: SUBSPACE_TYPE,
        id: subspaceId,
        relationships: {
          field_subspace_editors: {
            data: editorIds.map((editorId) => ({
              type: USER_TYPE,
              id: editorId
            }))
          }
        }
      }
    })
  })
}

export function updateSubspaceTitle({
  subspaceId,
  title
}: {
  subspaceId: string
  title: string
}) {
  return fetch(`${API_DOMAIN}/jsonapi/node/space/${subspaceId}`, {
    ...init,
    headers: {
      ...init.headers,
      'Content-Type': 'application/vnd.api+json'
    },
    method: 'PATCH',
    body: JSON.stringify({
      data: {
        type: SUBSPACE_TYPE,
        id: subspaceId,
        attributes: {
          title
        }
      }
    })
  })
}

export function updateSubspaceThumbnail({
  subspaceId,
  imgData
}: {
  subspaceId: string
  imgData: ImageToAttachToArticle
}) {
  return fetch(`${API_DOMAIN}/jsonapi/node/space/${subspaceId}`, {
    ...init,
    headers: {
      ...init.headers,
      'Content-Type': 'application/vnd.api+json'
    },
    method: 'PATCH',
    body: JSON.stringify({
      data: {
        type: SUBSPACE_TYPE,
        id: subspaceId,
        relationships: {
          field_space_media: {
            data: [
              {
                id: imgData.uuid,
                meta: { drupal_internal__target_id: imgData.vid },
                type: MEDIA_DAM_IMAGE_TYPE
              }
            ]
          }
        }
      }
    })
  })
}

export function updateSubspaceThumbnailWithLocalFile({
  subspaceId,
  subspaceLangCode,
  subspaceStatus,
  imgData
}: {
  subspaceId: string
  subspaceLangCode: string
  subspaceStatus: string
  imgData: {
    file: File
    meta: Record<string, string | string[]>
  }
}) {
  const formData = new FormData()

  const fileType = getFileType(imgData.file)

  const { category, country } = imgData?.meta

  const fileMetaPrepared = Object.entries(imgData?.meta).reduce(
    (res: Record<string, (string | string[])[]>, [key, value]) => {
      if (
        value &&
        key !== 'copyright' &&
        key !== 'country' &&
        key !== 'category'
      ) {
        res[key] = [value]
      }
      return res
    },
    {}
  )

  const formattedFileName = formatFileName(imgData.file.name)

  formData.append('file', imgData.file, formattedFileName)
  formData.append('profile', UploadProfiles.spacesUploads)
  formData.append(
    'category',
    [...(category || []), ...(country || [])].toString()
  )
  formData.append('langcode', subspaceLangCode)
  formData.append('status', subspaceStatus)
  formData.append(
    'metadata',
    JSON.stringify({
      fields: { ...fileMetaPrepared, filetype: [fileType] }
    })
  )

  return fetch(`${API_DOMAIN}/subspace/update/${subspaceId}`, {
    ...init,
    method: 'POST',
    body: formData
  })
}
